import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from '../../../environments/environment';

import '../../_shared/calendar/calendarResponse.model';

@Injectable({ providedIn: 'root' })

export class HomeService {
    private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public data$: Observable<any> = this.dataSubject.asObservable();

    constructor(private http: HttpClient) {}

    token: string = localStorage.getItem('token');

    readonly BaseUrl = environment.baseUrl;
    headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // tslint:disable-next-line: object-literal-key-quotes
            'Token': this.token
        })
    };

    populateCalendar(date: any): Observable<any> {
        const todayDate = this.formatDate(date);
        return this.http.get<any>(this.BaseUrl + `/Calendar/PopulateCalendar/?date=${todayDate}`, this.headers );
    }

    updateData(data: any) {
        this.populateCalendar(data).subscribe(
            (res: any) => {
                this.dataSubject.next(res);
            },
            err => console.error(err)
        );
    }

    formatDate(date: any): string {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();

        return `${yyyy}-${mm}-${dd}`;
    }

    getTodaysDate(): Observable<any> {
        return this.http.get<any>(this.BaseUrl + "/Calendar/GetTodaysDate", this.headers);
    }

    updateUserStatistics(date: any): Observable<any> {
        const todayDate = this.formatDate(date);
        return this.http.get<any>(this.BaseUrl + `/Calendar/UpdateUserStatistics/?date=${todayDate}`, this.headers );
    }

    getDeploymentVersion(): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Deployment/GetDeploymentVersion`, this.headers);
    }
}
