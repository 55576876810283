import {Component, OnInit, Input, Injectable, ViewChild} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EditEventService } from 'src/app/_services';

import { HomeService, DataService, EventListService, ImportService } from '../../_services';
import { DateListService } from 'src/app/_services/home/datelist.service';
import { YearListBase } from 'src/app/_shared/calendar/yearlist-base';
import { MonthListBase } from 'src/app/_shared/calendar/monthlist-base';

import { HomeHelper } from 'src/app/_helpers/home/home-helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VacationDialogComponent } from 'src/app/_components/home/vacation/vacation-dialog.component';
import { ImportDialogComponent } from 'src/app/_components/home/import-dialog/import-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { error } from 'util';
import {HeaderSharedComponent} from "../../shared/components/header/header-shared.component";
@Injectable({ providedIn: 'root' })

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [DatePipe]
})

export class HomeComponent implements OnInit {

    response = {
        calendar: [],
        expendedTime: 0,
        performanceIndicator: 0,
        user: { Id: 0, FullName: '', ChevronName: '', progress: 0, totalHours: 0, Freelancer: false, unusedVacationDays: 0 }
    };

    urlDate: string;
    name: string;

    date: any = new Date();
    d: any;
    dayOfWeek: any;

    @Input() public customers: any[] = [];
    @Input() public projects: any[] = [];
    @Input() public fileToUpload: File = null;
    @Input() public recentProject: any[] = [];

    selectedMonth: number;
    monthLevels: MonthListBase<any>[] = [];

    selectedYear: number;
    yearLevels: YearListBase<any>[] = [];

    connectedTo = [];
    currentYear: number;

    performanceRaiting: string;
    needVacation: boolean = false;
    tooMuchVacation: boolean = false;
    unusedVacation: number;
    tooltip: string;
    imgpath: string;
    isShown: boolean = false;
    initLock: boolean = false;

    constructor(private router: Router, private homeService: HomeService,
        private dataService: DataService, private eventListService: EventListService,
        private dateListService: DateListService, private homeHelper: HomeHelper,
        private modalService: NgbModal, private toastr: ToastrService, public dialog: MatDialog,
        public importService: ImportService, private editEventService: EditEventService) { }


    async ngOnInit() {
        try {
            if (this.initLock == true) {
                throw error;
            }

            this.initLock = true;
            this.dayOfWeek = this.date.getDay() !== new Date().getDay() ? 0 : this.date.getDay();
            if (localStorage.getItem('token') == null) {
                await this.router.navigateByUrl('/user/login');
            }
            await this.getRecentProjects().then(() => {
                this.importService.getCustomers().subscribe(
                    (res: any) => {
                        for (let i = 0; i < res.customers.length; i++) {
                            this.customers.push(res.customers[i]);
                        }
                        this.importService.getProjectsByCustomerID(this.recentProject[0].Project.Customer.Id).subscribe(
                            (res: any) => {
                                for (let i = 0; i < res.projects.length; i++) {
                                    this.projects.push(res.projects[i]);
                                }
                            },
                            err => console.error(err)
                        );
                    },
                    err => console.error(err)
                );
            });
            await this.redirectToMonthCheck();
            await this.redirectToYearCheck();
            this.selectedMonth = this.date.getMonth() + 1;
            this.selectedYear = this.date.getFullYear();

            this.yearLevels = this.dateListService.getYearsList();
            this.monthLevels = this.dateListService.getMonthsList(this.selectedYear);
            await this.homeService.populateCalendar(this.date).subscribe(
                (res: any) => {
                    let temparr: any;
                    const chunk = 7;
                    this.response.calendar = [];
                    for (let i = 0; i < res.calendar.length; i += chunk) {
                        temparr = res.calendar.slice(i, i + chunk);
                        this.response.calendar.push(temparr);
                        this.connectedTo.push(temparr.EventDate);
                    }
                    this.response.expendedTime = res.expendedTime;
                    this.response.user.unusedVacationDays = res.user.UnusedVacationDays;
                    this.response.performanceIndicator = res.performanceIndicator;
                    this.response.user.Id = res.user.Id;
                    this.response.user.FullName = res.user.FullName;
                    this.response.user.Freelancer = res.user.Freelancer;
                    this.connectedTo = this.dateListService.getConnectedList(res.calendar);
                    this.response.user.totalHours = res.totalUserHours;
                    this.response.user.ChevronName = this.homeHelper.hoursToChevron(res.totalUserHours);
                    this.response.user.progress = this.homeHelper.getChevronProgress(this.response.user.totalHours, this.response.user.ChevronName);

                    this.tooltip = this.response.user.unusedVacationDays + " vacation days left"
                    //this.unusedVacation = Math.round(this.totalVacation - this.response.vacationDays);

                    this.CheckVacations(this.response.user.unusedVacationDays);
                    this.NiceTooltipHelper(res.performanceIndicator);
                },
                err => console.error(err)
            );
            this.currentYear = new Date().getFullYear();
            this.RandomImage();
        } catch (e) {
            console.error(e);
        } finally {
            this.initLock = false;
        }
    }

    async validateFile(files) {
        let file = files[0];
        if (file.size < 10) {
            this.toastr.error("We are sorry. Attached file is empty", 'File is empty');
        } else if (file.type != "application/vnd.ms-excel" && file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            && file.type != "text/csv") {
            this.toastr.error("We are sorry. Format of data is not correct we support csv, xls, and xlsx", 'Incorrect format');
        } else {
            this.dialogClick();
        }
    }
    
    private RandomImage() {
        let a = Math.floor(Math.random() * 10);
        return this.imgpath = "/assets/img/random/pix" + a + ".png";
    }

    private CheckVacations(unusedVacationDays) {
        if (unusedVacationDays >= 10 && this.selectedMonth >= 9 && this.response.user.Freelancer != true) {
            this.needVacation = true;
            setTimeout(res => this.vacationModal(), 500);
        }
        if (unusedVacationDays < 0) {
            this.tooMuchVacation = true;
            this.unusedVacation = Math.abs(this.response.user.unusedVacationDays)
        }
    }

    private NiceTooltipHelper(performanceIndicator) {
        if (performanceIndicator < 50) {
            this.performanceRaiting = "Work Harder, Comrade!"
        }
        else if (performanceIndicator < 100 && performanceIndicator > 50) {
            this.performanceRaiting = "Not bad!"
        }
        else if (performanceIndicator > 200) {
            this.performanceRaiting = "Crazy!"
        }
        else {
            this.performanceRaiting = "Awesome!"
        }
    }

    getRecentProjects() {
        return new Promise((resolve) => {
            this.editEventService.populateEventWithRecentProjects().subscribe(
                (res: any) => {
                    this.recentProject = res.recentProjectsWithCustomers.slice(0);
                    resolve();
                },
                err => console.error(err)
            );
        });
    }

    selectDay(selectedDay: string) {
        this.eventListService.populateEventList(selectedDay)
            .subscribe((res: any) => {
                this.dataService.sendDatetime(res.eventList);
                this.dataService.sendUserId(this.response.user.Id);
                this.router.navigateByUrl(`/event-list/${selectedDay}`);
            },
                err => {
                    console.error(err);
                });
    }   

    createEvent(selectedDay: string) {
        this.dataService.sendUserId(this.response.user.Id);
        let resourse = "home";
        this.router.navigateByUrl(`/create-event/${selectedDay}/${resourse}`);
    }

    updateUserStatistics() {
        this.homeService.updateUserStatistics(this.date).subscribe(
            (res: any) => {
                this.response.expendedTime = res.expendedTime;
                this.response.performanceIndicator = res.performanceIndicator;
                this.response.user.totalHours = res.totalUserHours;
                this.response.user.ChevronName = this.homeHelper.hoursToChevron(res.totalUserHours);
                this.response.user.progress = this.homeHelper.getChevronProgress(this.response.user.totalHours, this.response.user.ChevronName);
                this.response.user.unusedVacationDays = res.unusedVacationDays;
                this.homeService.updateData(this.date);
            },
            err => console.log(err)
        );
    }

    redirectToMonthCheck() {
        if (sessionStorage.getItem('month') === null) {
            sessionStorage.setItem('month', this.date.getMonth() + 1);
        }
        let monthCheck = sessionStorage.getItem('month');
        if (this.selectedMonth != parseInt(monthCheck)) {
            this.date.setMonth(parseInt(monthCheck) - 1);
        }
    }

    redirectToYearCheck() {
        if (sessionStorage.getItem('year') === null) {
            sessionStorage.setItem('year', this.date.getFullYear());
        }
        let yearCheck = sessionStorage.getItem('year');
        if (this.selectedYear != parseInt(yearCheck)) {
            this.date.setYear(parseInt(yearCheck));
        }
    }

    vacationModal() {
        var showModal = sessionStorage.getItem('modal-vacation');
        if (localStorage.getItem('token') != null && showModal != 'true') {
            if (!navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|BB|PlayBook|Windows Phone/i)) {
                sessionStorage.setItem('modal-vacation', 'true');
                const modalRef = this.modalService.open(VacationDialogComponent, { centered: true });
                modalRef.result.catch(() => false);
            }
        }
    }

    dialogClick() {
        const modalRef = this.modalService.open(ImportDialogComponent, { centered: true });
        modalRef.componentInstance.fileToUpload = this.fileToUpload;
        modalRef.componentInstance.customers = this.customers;
        modalRef.componentInstance.projects = this.projects;
        modalRef.componentInstance.recentProject = this.recentProject;
        modalRef.result.catch(() => false);
    }
}

